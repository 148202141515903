<template>
  <v-container>
    <TopBar />
    <v-row style="width: 100%">
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <div v-if="!isMobile()">
          <p style="font-size: 22px; margin-left: 10%">
            <br />Heeft u een opdracht dat valt onder: <br />
            - Websites <br />
            - C# <br />
            - Java <br />
            - Cyber Security <br />
            - Solidity <br />

            <br />
            Neem dan contact op via de mail of met dit contacten formulier!
          </p>
        </div>
        <div v-else>
          <p
            style="
              font-size: 18px;
              margin-left: 1%;
              margin-top: -2%;
              margin-bottom: -7%;
            "
          >
            <br />Heeft u een opdracht dat valt onder: <br />
            - Websites <br />
            - C# <br />
            - Java <br />
            - Cyber Security <br />
            - Solidity <br />

            <br />
            Neem dan contact op via de mail of met dit contacten formulier!
          </p>
        </div>
      </v-col>
      <v-col
        style="padding-right: 0%"
        cols="12"
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
      >
        <p style="margin-top: 12%; font-size: 22px">
          <b>Contact Formulier </b>
        </p>
        <v-form style="margin-top: 2%">
          <v-text-field
            v-model="firstname"
            label="Firstname"
            required
          ></v-text-field>
          <v-text-field
            v-model="lastname"
            label="Last name"
            required
          ></v-text-field>
          <v-text-field v-model="email" label="Email" required></v-text-field>
          <v-textarea
            v-model="description"
            label="Description"
            outlined
          ></v-textarea>
          <v-btn>Submit</v-btn>
        </v-form>
      </v-col>
    </v-row>
    <div v-if="isMobile()" style="height: 200px"></div>
    <BottomFooter />
  </v-container>
</template>

<script>
import BottomFooter from "../components/BottomFooter.vue";
import TopBar from "@/components/TopBar.vue";

export default {
  name: "ContactView",

  components: {
    BottomFooter,
    TopBar,
  },

  methods: {
    isMobile() {
      return screen.width <= 760;
    },
  },
};
</script>
