<template>
  <v-app>
    <v-main
      style="
        padding: auto;
        margin: auto;
        flex: 1;
        overflow: hidden;
        width: 100vw;
        height: 100vh;
      "
    >
      <router-view />
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: "App",

  data: () => ({
    //
  }),
};
</script>

<style>
@font-face {
  font-family: "Comfortaa";
  src: local("Comfortaa"), url(./fonts/Comfortaa-Regular.ttf) format("truetype");
}
</style>
