import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/HomeView.vue";
import CompanyView from "../views/CompanyView.vue";
import AboutMeView from "../views/AboutMeView.vue";
import ContactView from "../views/ContactView.vue";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
  },
  {
    path: "/Company",
    name: "CompanyView",
    component: CompanyView,
  },
  {
    path: "/Aboutme",
    name: "AboutMeView",
    component: AboutMeView,
  },
  {
    path: "/Contact",
    name: "ContactView",
    component: ContactView,
  },
  {
    path: "/Projects",
    name: "ProjectsView",
    component: () =>
      import(
        /* webpackChunkName: "ProjectsView" */ "../views/ProjectsView.vue"
      ),

    children: [],
  },
  // {
  //   path: "/about",
  //   name: "about",
  //   // route level code-splitting
  //   // this generates a separate chunk (about.[hash].js) for this route
  //   // which is lazy-loaded when the route is visited.
  //   component: () =>
  //     import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  // },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
