import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    firstTimeIntro: true,
  },
  getters: {
    getFirstTimeIntro: (state) => state.firstTimeIntro,
  },
  mutations: {
    IntroHappened(state) {
      state.firstTimeIntro = false;
    },
  },
  actions: {},
  modules: {},
});
