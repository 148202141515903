<template>
  <v-container style="margin: 0%; max-width: none; padding: 0%">
    <TopBar />
    <v-row style="width: 100%">
      <v-col cols="12" xs="12" sm="12" md="6" lg="6" xl="6">
        <div v-if="!isMobile()">
          <p style="font-size: 14px; margin-left: 30%; margin-top: 5%">
            <br />
            Mijn naam is Duncan Schoenmakers en ik ben een 24-jarige software
            engineer met een specialisatie in cybersecurity. <br />
            <br />
            Ik heb ervaring in diverse programmeertalen, waaronder C#, Java en
            Python. <br />
            Mijn voortdurende streven naar kennisuitbreiding in mijn vakgebieden
            is een belangrijke drijfveer en ik geniet ervan om nieuwe
            vaardigheden te ontwikkelen. <br />
            <br />
            Binnen het domein van cybersecurity richt ik mij voornamelijk op het
            identificeren en aanpakken van beveiligingsrisico's, maar ik bezit
            ook aanzienlijke kennis en ervaring in het beschermen van
            organisaties tegen bedreigingen. Ik ben gepassioneerd over mijn werk
            en zet mij in voor het waarborgen van de veiligheid en integriteit
            van bedrijven. <br />
          </p>
          <v-btn
            style="margin-left: 30%"
            class="text-none"
            x-large
            color="primary"
            @click="toProjects()"
            >Mijn Projecten</v-btn
          >
          <div style="height: 100px"></div>
        </div>
        <div v-else>
          <p style="font-size: 18px; margin-left: 5%; margin-top: 5%">
            <br />
            Mijn naam is Duncan Schoenmakers en ik ben een 24-jarige software
            engineer met een specialisatie in cybersecurity. <br />
            <br />
            Ik heb ervaring in diverse programmeertalen, waaronder C#, Java en
            Python. <br />
            Mijn voortdurende streven naar kennisuitbreiding in mijn vakgebieden
            is een belangrijke drijfveer en ik geniet ervan om nieuwe
            vaardigheden te ontwikkelen. <br />
            <br />
            Binnen het domein van cybersecurity richt ik mij voornamelijk op het
            identificeren en aanpakken van beveiligingsrisico's, maar ik bezit
            ook aanzienlijke kennis en ervaring in het beschermen van
            organisaties tegen bedreigingen. Ik ben gepassioneerd over mijn werk
            en zet mij in voor het waarborgen van de veiligheid en integriteit
            van bedrijven. <br />
          </p>
          <v-btn
            style="margin-left: 5%"
            class="text-none"
            x-large
            color="primary"
            @click="toProjects()"
            >Mijn Projecten</v-btn
          >
          <div style="height: 200px"></div>
        </div>
      </v-col>
      <v-col
        v-if="!isMobile()"
        style="padding-right: 0%"
        cols="12"
        xs="12"
        sm="12"
        md="6"
        lg="6"
        xl="6"
      >
        <v-img
          style="
            height: auto;
            width: 43vw;
            text-align: right;
            position: relative;
            left: 8%;
          "
          src="../assets/background-company.png"
        ></v-img>
      </v-col>
    </v-row>
    <BottomFooter />
  </v-container>
</template>

<script>
import BottomFooter from "../components/BottomFooter.vue";
import TopBar from "@/components/TopBar.vue";

export default {
  name: "AboutMeView",

  components: {
    BottomFooter,
    TopBar,
  },

  methods: {
    isMobile() {
      return screen.width <= 760;
    },
    toProjects() {
      this.$router.push("Projects");
    },
  },
};
</script>
